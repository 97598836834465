import React from 'react';
import { Typeahead  } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';

// Styles
import './style.scss';

export type OptionItem = {
    [key: string]: any
};

interface TypeaheadSelect {
    options: OptionItem[],
    value?: string | number
    placeholder?: string,
    name: string,
    className?: string,
    onChange: (name: string, value: number | string) => void,
    label?: string,
    error?: string,
    displayKey?: string
}
const TypeaheadSelect = ({options, placeholder, value = -1, name, className, onChange, label, error, displayKey}: TypeaheadSelect) => {

    const handleChange = (selected: any) => {
        onChange(name, selected[0]?.id ?? -1);
    };

    return (
        <div className={`d-flex flex-column ${className}`}>
            {label &&
                <label className="mb-1 fw-bold">
                    {label}
                </label>
            }
            <Typeahead
                clearButton
                id={`typeahead-select-${name}`}
                options={options}
                selected={options.filter((item) => item.id === value)}
                onChange={handleChange}
                labelKey="name"
                placeholder={placeholder}
                className={`h-40 rounded-3 w-huge`}
            />
            {error &&
                <p className="error">{error}</p>
            }
        </div>
    );
};

export default TypeaheadSelect;

import React, {ChangeEvent, useRef} from 'react';
import './style.scss';
import {v4 as uuidv4} from 'uuid';

// Components
import {Form} from 'react-bootstrap';

interface CustomInputProps {
    type?: string
    size?: "small" | "medium" | "large" | "huge"
    placeholder: string
    onChange: (name: string, value: number | string) => void
    name: string
    value?: string | number
    className?: string,
    label?: string,
    error?: string
    readOnly?: boolean
}

const CustomInput = ({type = "text", size = "medium", placeholder, onChange, name, value, className, label, error, readOnly}: CustomInputProps) => {
    const input = useRef<HTMLInputElement>(null);
    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        onChange(name, event.target.value);
    };

    const generateSis = () => {
        const sisId = uuidv4();
        // @ts-ignore
        input.current.value = sisId;

        onChange(name, sisId);
    };

    return (
        <div className={`d-flex flex-column ${className}`}>
            {label &&
                <label className="mb-1 fw-bold">
                    {label}
                </label>
            }
            <Form.Control
                type={type}
                ref={input}
                name={name}
                defaultValue={value}
                className={`rounded-3 h-40 p-2 w-${size}`}
                placeholder={placeholder}
                onChange={handleChange}
                disabled={readOnly}
            />
            {name === 'sis_id' &&
                <span
                    className="auto-generate text-small cursor-pointer"
                    onClick={generateSis}
                >Auto-generate</span>
            }
            {error &&
                <p className="error">{error}</p>
            }
        </div>
    )
}

export default CustomInput;

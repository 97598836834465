import React, {useState} from 'react';

// Components
import AvatarsPopover from '../Modals/AvatarsModal';

interface AvatarComponentProps {
    avatar: number;
    className?: string;
    name: string;
    onChange: (name: string, value: number | string) => void
}

const AvatarComponent = ({avatar = 1, className, name, onChange}: AvatarComponentProps) => {
    const [showModal, setShowModal] = useState(false);

    const image = require(`./../../assets/avatars/avatar-${avatar}.png`);

    const onAvatarChange = (value: number) => {
        onChange(name, value);
        setShowModal(false);
    };

    return (
        <div className={`d-flex flex-column ${className}`}>
            <label className="mb-1 fw-bold">
                Avatar
            </label>
            <img
                src={image}
                alt="avatar"
                width={100}
                height={100}
                className="cursor-pointer border-1 border rounded-2 p-1 border-light-gray"
                onClick={() => setShowModal(!showModal)}
            />
            <AvatarsPopover
                show={showModal}
                onAvatarCheck={onAvatarChange}
                close={() => setShowModal(false)}
            />
        </div>
    )
}

export default AvatarComponent;

import React, {useEffect, useState} from 'react';
import {useAppDispatch, useAppSelector} from '../../hooks/redux';
import {useNavigate} from 'react-router-dom';
import {useStateMap} from 'react-us-states';

// Reducers
import {selectDistrictState} from '../../store/districts/reducer';

// Components
import ListTableHeaderComponent from '../../components/ListTableHeader';
import ListTableWrapper from '../../components/ListTable/TableWrapper';
import FiltersComponent from '../../components/Filters';
import {TailSpin} from 'react-loader-spinner';
import {Container, Row, Col, Card, Button} from 'react-bootstrap';

// Images
import plusIcon from '../../assets/plus-icon.png';

// Configs
import {getDistrictsFilterConfig, getDistrictsListColumns} from '../../config/confings';

// Actions
import {
    deleteDistrict,
    getDistricts,
    setDistrictsFilters,
    setDistrictsPagination,
    setDistrictsSort
} from '../../store/districts/actions';

// Constants
import {getStates} from '../../constants';

const DistrictsListPage = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const statesMap = useStateMap();
    const [isLoading, setIsLoading] = useState(true);
    const {districts, filters, pagination, sort} = useAppSelector(selectDistrictState);

    useEffect(() => {
        (async () => {
            dispatch(setDistrictsPagination({"perPage": 10, "page": 1}));
            await getList();
            setIsLoading(false);
        })();
    },[])

    const createDistrict = () => {
        navigate("/districts/create-district", {replace: true});
    };

    const setFilters = async (data: object) => {
        dispatch(setDistrictsFilters(data));
        await getList();
    };

    const getList = async () => {
        await dispatch(getDistricts());
    };

    const handlePagination = async (name: string, value: number | string) => {
        dispatch(setDistrictsPagination({[name]: +value}));
        if (name === "perPage") {
            dispatch(setDistrictsPagination({"page": 1}));
        }
        await getList();
    };

    const handleSort = async (value: string) => {
        dispatch(setDistrictsSort(value));
        await getList();
    };

    const deleteItem = async (id: number) => {
        await dispatch(deleteDistrict(id));
    };

    const viewItem = (id: number) => {
        navigate(`/districts/${id}`, {replace: true});
    };

    const editItem = (id: number) => {
        navigate(`/districts/${id}/edit`, {replace: true});
    };

    const getTableHeader = () => {
        return (
            <ListTableHeaderComponent
                filters={filters}
                handlePagination={handlePagination}
                itemsName="districts"
                pagination={pagination}
                setFilters={setFilters}
            />
        )
    };

    return (
        <Container className="mb-4">
            <Row>
                <Col>
                    <Card>
                        <Card.Body>
                            <div className="d-flex justify-content-between align-middle">
                                <h1 className="m-0">Districts</h1>
                                <Button variant="primary" className="rounded mt-1 d-flex align-items-center" onClick={() => createDistrict()}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-plus" viewBox="0 0 16 16">
                                        <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z"/>
                                    </svg>
                                    Create district
                                </Button>
                            </div>
                            <hr/>
                            <FiltersComponent
                                filterConfig={getDistrictsFilterConfig(getStates(statesMap))}
                                initialFilters={filters}
                                setFilters={setFilters}
                            />
                            <hr/>
                            {isLoading
                                ? <div className="d-flex justify-content-center align-items-center my-5">
                                    <TailSpin
                                        height="400"
                                        width="80"
                                        color="#53c154"
                                        visible={true}
                                    />
                                </div>
                                :  <ListTableWrapper
                                    pagination={pagination}
                                    handlePagination={handlePagination}
                                    header={getTableHeader()}
                                    handleSort={handleSort}
                                    sort={sort}
                                    listData={districts}
                                    columns={getDistrictsListColumns(deleteItem, viewItem, editItem)}
                                />
                            }
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>
    )
}

export default DistrictsListPage;

import React, {useState} from 'react';
import {useAppDispatch, useAppSelector} from '../../hooks/redux';
import {get} from 'lodash';

// Components
import {Link, useLocation} from 'react-router-dom';
import {ReactComponent as Logo} from '../../assets/cirs-logo.svg';
import {ReactComponent as BeaconLogo} from '../../assets/beacon-icon.svg';
import {ReactComponent as DrcoLogo} from '../../assets/drco-icon.svg';
import {ReactComponent as CicoLogo} from '../../assets/cico-icon.svg';
import {Button} from 'react-bootstrap';

// Styles
import './style.scss';

// Reducers
import {selectAuthState} from '../../store/auth/reducer';

// Actions
import {logout} from '../../store/auth/actions';

// Helpers
import {getLocalStorage, isAdmin, isDistrictAdminOrHigher, removeLocalStorage} from '../../helpers';
import {LocalStorageKeys} from '../../store/keys';

const Header = () => {
    const {authUser} = useAppSelector(selectAuthState);
    const dispatch = useAppDispatch();
    const {pathname} = useLocation();
    const [showUtilityMenu, setShowUtilityMenu] = useState(false);
    const [showAppMenu, setShowAppMenu] = useState(false);

    const userRole = get(authUser, 'role.name');
    const isImpersonate = getLocalStorage(LocalStorageKeys.isImpersonate);

    const signOut = async () => {
        //await dispatch(logout());
        window.location.href = process.env.REACT_APP_SSO_SERVER_URL + "/logout-user?redirect_url=" + process.env.REACT_APP_URL;
    };

    const goBack = () => {
        removeLocalStorage(LocalStorageKeys.isImpersonate);
        window.location.href = process.env.REACT_APP_URL as string;
    };

    const isActive = (path: string) => {
        return pathname.includes(path) ? 'active-link' : '';
    };

    return (
        <div id="main-nav-container">
            <Button className={'app-menu-toggle' + (showAppMenu ? ' active' : '')} onClick={() => setShowAppMenu(!showAppMenu)}>
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-grid-3x3-gap-fill" viewBox="0 0 16 16">
                    <path d="M1 2a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2zm5 0a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1H7a1 1 0 0 1-1-1V2zm5 0a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1h-2a1 1 0 0 1-1-1V2zM1 7a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V7zm5 0a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1H7a1 1 0 0 1-1-1V7zm5 0a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1h-2a1 1 0 0 1-1-1V7zM1 12a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1v-2zm5 0a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1H7a1 1 0 0 1-1-1v-2zm5 0a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1h-2a1 1 0 0 1-1-1v-2z"/>
                </svg>
            </Button>
            <Logo className="logo" />
            <div className="d-flex align-items-center">
                <Link to={"/users"} className={`text-decoration-none me-4 header-link ${isActive('users')}`}>Users</Link>
                <Link to={"/students"} className={`text-decoration-none me-4 header-link ${isActive('students')}`}>Students</Link>
                {isDistrictAdminOrHigher(userRole) &&
                    <Link to={"/schools"} className={`text-decoration-none me-4 header-link ${isActive('schools')}`}>Schools</Link>
                }
                {isAdmin(userRole) &&
                    <Link to={"/districts"} className={`text-decoration-none me-4 header-link ${isActive('districts')}`}>Districts</Link>
                }
                {isAdmin(userRole) &&
                    <>
                        <Link to={"/cohorts"} className={`text-decoration-none me-4 header-link ${isActive('cohorts')}`}>Cohorts</Link>
                        {/* <Link to={"/connected-apps"} className={`text-decoration-none header-link ${isActive('connected-apps')}`}>Connected Apps</Link> */}
                    </>
                }
            </div>
            <div className={'utility-nav-toggle' + (showUtilityMenu ? ' active' : '')}>
                <div className="nav-toggle d-flex justify-content-center align-items-center cursor-pointer" onClick={() => setShowUtilityMenu(!showUtilityMenu)}>
                    <span>{authUser.first_name} {authUser.last_name}</span>
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-person-circle" viewBox="0 0 16 16">
                        <path d="M11 6a3 3 0 1 1-6 0 3 3 0 0 1 6 0z"/>
                        <path fillRule="evenodd" d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8zm8-7a7 7 0 0 0-5.468 11.37C3.242 11.226 4.805 10 8 10s4.757 1.225 5.468 2.37A7 7 0 0 0 8 1z"/>
                    </svg>  
                </div>                
            </div>
            { showAppMenu &&
                <div className="app-menu-container">
                    <div className="app-menu">
                        <h2>Switch to:</h2>
                        <a href={process.env.REACT_APP_BEACON_URL} className="app-menu-item beacon">
                            <BeaconLogo /> Beacon for Schools
                        </a>
                        <a href={process.env.REACT_APP_DRCO_URL} className="app-menu-item">
                            <DrcoLogo /> Daily Report Card Online
                        </a>
                        <a href={process.env.REACT_APP_CICO_URL} className="app-menu-item">
                            <CicoLogo /> Check-In Check-Out
                        </a>
                    </div>
                    <div className="utility-menu-backdrop" onClick={() => setShowAppMenu(!showAppMenu)}></div>
                </div>
            }
            { showUtilityMenu &&
                <div className="utility-menu-container">
                    <div className="utility-menu">
                        <a className="utility-menu-item" onClick={isImpersonate ? goBack : signOut}>
                            {isImpersonate ? 'Go Back' : 'Sign Out'}
                        </a>
                    </div>
                    <div className="utility-menu-backdrop" onClick={() => setShowUtilityMenu(!showUtilityMenu)}></div>
                </div>
            }
        </div>
    )
}

export default Header;

import React from 'react';
import {get} from 'lodash';

// Images
import settings from '../assets/settings.png';

// Components
import TableOptionDropdown from '../components/ListTable/TableOptionDropdown';

// Interfaces
import {IDistrict} from '../models/IDistrict';
import {IStudent} from '../models/IStudent';
import {ISchool} from '../models/ISchool';
import {ICohort} from '../models/ICohort';
import {IRole} from '../models/IRole';
import {IUser} from '../models/IUser';
import {IApp} from '../models/IApp';

// Helpers
import {formatDate, isAdmin, isDistrictAdminOrHigher} from '../helpers';

// Constants
import {UserRoles} from '../constants';

const getAppsNames = (apps: IApp[]) => {
    const names: string[] = [];
    apps.map((app: IApp) => {
        names.push(app.name);
    });

    return names.join(',');
};

export const getUsersListColumns = (deleteUser: (id: number) => void, viewUser: (id: number) => void,
                                    editUser: (id: number) => void, resendEmail: (email: string) => void,
                                    isAdmin: boolean, impersonate: (id: number) => void) => ([
    {
        title: "Id",
        key: "id",
        getItemValue: (item: IUser) => ({
            text: item.id
        })
    },
    {
        title: "Last name",
        key: "last_name",
        getItemValue: (item: IUser) => ({
            text: item.last_name
        })
    },
    {
        title: "First name",
        key: "first_name",
        getItemValue: (item: IUser) => ({
            text: item.first_name
        })
    },
    {
        title: "Email",
        key: "email",
        getItemValue: (item: IUser) => ({
            text: item.email
        })
    },
    {
        title: "Role",
        key: "role",
        getItemValue: (item: IUser) => ({
            text: get(item, ['role', 'display_name'])
        })
    },
    {
        title: "School",
        key: "school",
        getItemValue: (item: IUser) => ({
            text: get(item, ['school', '0', 'name'])
        })
    },
    // {
    //     title: "District",
    //     key: "district",
    //     getItemValue: (item: IUser) => ({
    //         text: get(item, ['district', '0', 'name'])
    //     })
    // },
    {
        title: <img src={settings} alt="options"/>,
        style: "text-center",
        render: (item: IUser) =>
            <TableOptionDropdown
                onDelete={()=> deleteUser(item.id)}
                onEdit={()=>editUser(item.id)}
                onView={()=>viewUser(item.id)}
                resendEmail={() => resendEmail(item.email)}
                impersonate={() => impersonate(item.id)}
                role={item.role}
                isAdmin={isAdmin}
                confirmText="Delete User"
                modalBodyText="Are you sure you would like to delete this user?"
            />
    }
]);

export const getUsersFilterConfig = (roles: IRole[], schools: ISchool[], districts: IDistrict[], cohorts: ICohort[], userRole: string) => ([
    {
        fieldType: "input",
        name: "id",
        type: "number",
        placeholder: "ID",
        size: "small"
    },
    {
        fieldType: "input",
        name: "email",
        type: "text",
        placeholder: "Email",
        size: "large"
    },
    {
        fieldType: "input",
        name: "first_name",
        type: "text",
        placeholder: "First Name",
        size: "medium"
    },
    {
        fieldType: "input",
        name: "last_name",
        type: "text",
        placeholder: "Last Name",
        size: "medium"
    },
    {
        fieldType: "select",
        name: "role",
        placeholder: "User Role",
        size: "medium",
        displayKey: "display_name",
        options: roles
    },
    isDistrictAdminOrHigher(userRole) &&
    {
        fieldType: "select",
        name: "school",
        placeholder: "School",
        size: "large",
        options: schools
    },
    isAdmin(userRole) &&
    {
        fieldType: "select",
        name: "district",
        placeholder: "District",
        size: "large",
        options: districts
    },
    isAdmin(userRole) &&
    {
        fieldType: "select",
        name: "cohort",
        placeholder: "Cohort",
        size: "large",
        options: cohorts
    }
]);

export const getUserEditConfig = (roles: IRole[], schools: ISchool[], districts: IDistrict[], cohorts: ICohort[], apps: IApp[], isSelfConfig: boolean, userRole: string, isSelectedDistrict: boolean) => ([
    {
        fieldType: "input",
        name: "email",
        type: "email",
        label: "Email"
    },
    {
        fieldType: "input",
        name: "first_name",
        type: "text",
        label: "First Name"
    },
    {
        fieldType: "input",
        name: "last_name",
        type: "text",
        label: "Last Name"
    },
    isSelfConfig &&
    {
        fieldType: "input",
        name: "old_password",
        type: "password",
        label: "Old password"
    },
    isSelfConfig &&
    {
        fieldType: "input",
        name: "new_password",
        type: "password",
        label: "New Password"
    },
    {
        fieldType: "select",
        name: "role",
        label: "Role",
        displayKey: "display_name",
        options: roles
    },
    ![UserRoles.DistrictAdmin, UserRoles.SchoolAdmin].includes(userRole) &&
    {
        fieldType: "typeahead_select",
        name: "district",
        label: "District",
        options: districts
    },
    userRole !== UserRoles.SchoolAdmin && isSelectedDistrict &&
    {
        fieldType: "typeahead_select",
        name: "school",
        label: "School",
        options: schools
    },
    isAdmin(userRole) &&
    {
        fieldType: "select",
        name: "cohort",
        label: "Cohort",
        options: cohorts
    },
    isAdmin(userRole) &&
    {
        fieldType: "multiple_checkbox",
        name: "apps",
        label: "App Access",
        options: apps
    }
]);

export const getUserStudentsConfig = (removeStudent: (id: number) => void, viewStudent: (id: number) => void, editStudent: (id: number) => void) => ([
    {
        title: "Id",
        key: "id",
        getItemValue: (item: IStudent) => ({
            text: item.id
        })
    },
    {
        title: "Last Name",
        key: "last_name",
        getItemValue: (item: IStudent) => ({
            text: item.last_name
        })
    },
    {
        title: "First Name",
        key: "first_name",
        getItemValue: (item: IStudent) => ({
            text: item.first_name
        })
    },
    {
        title: "School",
        key: "school",
        getItemValue: (item: IStudent) => ({
            text: item.school_name
        })
    },
    {
        title: "District",
        key: "district",
        getItemValue: (item: IStudent) => ({
            text: item.district_name
        })
    },
    {
        title: <img src={settings} alt="options"/>,
        style: "text-center",
        render: (item: IStudent) =>
            <TableOptionDropdown
                onDelete={()=> removeStudent(item.id)}
                onView={()=> viewStudent(item.id)}
                onEdit={()=> editStudent(item.id)}
                confirmText="Remove Access"
                modalBodyText="Are you sure you would like to remove access to this student?"
            />
    },
]);

export const getUserDetailsConfig = (user: IUser) => ({
    title: `${user.first_name} ${user.last_name}`,
    leftColumn: [
        {
            label: "User ID",
            value: user.id
        },
        {
            label: "Email",
            value: user.email
        },
        {
            label: "Last login",
            value: formatDate(user.last_login_at)
        },
        {
            label: "Agreed to terms",
            value: user.agreed_to_terms ? "Yes" : "No"
        },
        {
            label: "App access",
            value: getAppsNames(user.connected_apps)
        }
    ],
    rightColumn: [
        {
            label: "Role",
            value: get(user, ['role', 'display_name'], '-')
        },
        {
            label: "District",
            value: get(user, ['district', '0', 'name'], '-')
        },
        {
            label: "School",
            value: get(user, ['school', '0', 'name'], '-')
        },
        {
            label: "Cohort",
            value: get(user, ['cohorts', '0', 'name'], '-')
        },
    ]
});

export const getDistrictsListColumns = (deleteDistrict: (id: number) => void, viewDistrict: (id: number) => void, editDistrict: (id: number) => void) => ([
    {
        title: "Id",
        key: "id",
        getItemValue: (item: IDistrict) => ({
            text: item.id
        })
    },
    {
        title: "Name",
        key: "name",
        getItemValue: (item: IDistrict) => ({
            text: item.name
        })
    },
    {
        title: "City",
        key: "city",
        getItemValue: (item: IDistrict) => ({
            text: item.city
        })
    },
    {
        title: "State",
        key: "state",
        getItemValue: (item: IDistrict) => ({
            text: item.state
        })
    },
    {
        title: "Email",
        key: "email",
        getItemValue: (item: IDistrict) => ({
            text: item.contact_email ?? "-"
        })
    },
    {
        title: "Phone",
        key: "phone",
        getItemValue: (item: IDistrict) => ({
            text: item.contact_phone ?? "-"
        })
    },
    {
        title: "Enabled",
        key: "enabled",
        getItemValue: (item: IDistrict) => ({
            text: item.enabled ? "Yes" : "No"
        })
    },
    {
        title: <img src={settings} alt="options"/>,
        style: "text-center",
        render: (item: IUser) =>
            <TableOptionDropdown
                onDelete={()=> deleteDistrict(item.id)}
                onEdit={()=>editDistrict(item.id)}
                onView={()=>viewDistrict(item.id)}
                confirmText="Delete District"
                modalBodyText="Are you sure you would like to delete this district?"
            />
    }
]);

export const getDistrictsFilterConfig = (states: any) => ([
    {
        fieldType: "input",
        name: "id",
        type: "number",
        placeholder: "ID",
        size: "small"
    },
    {
        fieldType: "input",
        name: "name",
        type: "text",
        placeholder: "Name",
        size: "large"
    },
    {
        fieldType: "input",
        name: "city",
        type: "text",
        placeholder: "City",
        size: "medium"
    },
    {
        fieldType: "select",
        name: "state",
        placeholder: "State",
        size: "medium",
        options: states
    },
]);

export const getDistrictEditConfig = (states: {id: string, name: string}[]) => ([
    {
        fieldType: "input",
        name: "name",
        type: "text",
        label: "Name"
    },
    {
        fieldType: "input",
        name: "sis_id",
        type: "text",
        label: "SIS ID"
    },
    {
        fieldType: "input",
        name: "street_address",
        type: "text",
        label: "Street Address"
    },
    {
        fieldType: "input",
        name: "apt_suite",
        type: "text",
        label: "Apt/Suite"
    },
    {
        fieldType: "input",
        name: "city",
        type: "text",
        label: "City"
    },
    {
        fieldType: "select",
        name: "state",
        size: "medium",
        label: "State",
        options: states
    },
    {
        fieldType: "input",
        name: "postal_code",
        size: "medium",
        type: "text",
        label: "Postal Code"
    },
    {
        fieldType: "input",
        name: "contact_name",
        type: "text",
        label: "Contact Name"
    },
    {
        fieldType: "input",
        name: "contact_position",
        type: "text",
        label: "Contact Position"
    },
    {
        fieldType: "input",
        name: "contact_email",
        type: "text",
        label: "Contact Email"
    },
    {
        fieldType: "input",
        name: "contact_phone",
        type: "text",
        label: "Contact Phone"
    },
    {
        fieldType: "switch",
        name: "enabled",
        label: "Enabled"
    }
]);

export const getDistrictDetailsConfig = (district: IDistrict) => ({
    title: district.name,
    leftColumn: [
        {
            label: "District ID",
            value: district.id
        },
        {
            label: "Street Address",
            value: district.street_address
        },
        {
            label: "Apt/Suite",
            value: district.apt_suite
        },
        {
            label: "City, State",
            value: district.city + ", " + district.state
        },
        {
            label: "Postal Code",
            value: district.postal_code
        }
    ],
    rightColumn: [
        {
            label: "Contact Name",
            value: district.contact_name
        },
        {
            label: "Contact Position",
            value: district.contact_position
        },
        {
            label: "Contact Email",
            value: district.contact_email
        },
        {
            label: "Contact Phone",
            value: district.contact_phone
        },
        {
            label: "SIS ID",
            value: district.sis_id
        }
    ]
});

export const getSchoolsListColumns = (deleteSchool: (id: number) => void, viewSchool: (id: number) => void, editSchool: (id: number) => void) => ([
    {
        title: "Id",
        key: "id",
        getItemValue: (item: ISchool) => ({
            text: item.id
        })
    },
    {
        title: "Name",
        key: "name",
        getItemValue: (item: ISchool) => ({
            text: item.name
        })
    },
    {
        title: "City",
        key: "city",
        getItemValue: (item: ISchool) => ({
            text: item.city
        })
    },
    {
        title: "State",
        key: "state",
        getItemValue: (item: ISchool) => ({
            text: item.state
        })
    },
    {
        title: "District",
        key: "district",
        getItemValue: (item: ISchool) => ({
            text: get(item, ['district', 'name'], '-')
        })
    },
    {
        title: "Enabled",
        key: "enabled",
        getItemValue: (item: ISchool) => ({
            text: item.enabled ? "Yes" : "No"
        })
    },
    {
        title: <img src={settings} alt="options"/>,
        style: "text-center",
        render: (item: IUser) =>
            <TableOptionDropdown
                onDelete={()=> deleteSchool(item.id)}
                onEdit={()=>editSchool(item.id)}
                onView={()=>viewSchool(item.id)}
                confirmText="Delete School"
                modalBodyText="Are you sure you would like to delete this school?"
            />
    }
]);

export const getSchoolsFilterConfig = (states: any, districts: IDistrict[], userRole: string) => ([
    {
        fieldType: "input",
        name: "id",
        type: "number",
        placeholder: "ID",
        size: "small"
    },
    {
        fieldType: "input",
        name: "name",
        type: "text",
        placeholder: "Name",
        size: "large"
    },
    {
        fieldType: "input",
        name: "city",
        type: "text",
        placeholder: "City",
        size: "medium"
    },
    {
        fieldType: "select",
        name: "state",
        placeholder: "State",
        size: "medium",
        options: states
    },
    isAdmin(userRole) &&
    {
        fieldType: "select",
        name: "district",
        placeholder: "District",
        size: "medium",
        options: districts
    }
]);

export const getSchoolEditConfig = (states: {id: string, name: string}[], districts: IDistrict[]) => ([
    {
        fieldType: "input",
        name: "name",
        type: "text",
        label: "Name"
    },
    {
        fieldType: "input",
        name: "sis_id",
        type: "text",
        label: "SIS ID"
    },
    {
        fieldType: "select",
        name: "district",
        label: "District",
        options: districts
    },
    {
        fieldType: "input",
        name: "street_address",
        type: "text",
        label: "Street Address"
    },
    {
        fieldType: "input",
        name: "apt_suite",
        type: "text",
        label: "Apt/Suite"
    },
    {
        fieldType: "input",
        name: "city",
        type: "text",
        label: "City"
    },
    {
        fieldType: "select",
        name: "state",
        size: "medium",
        label: "State",
        options: states
    },
    {
        fieldType: "input",
        name: "postal_code",
        size: "medium",
        type: "text",
        label: "Postal Code"
    },
    {
        fieldType: "input",
        name: "contact_name",
        type: "text",
        label: "Contact Name"
    },
    {
        fieldType: "input",
        name: "contact_position",
        type: "text",
        label: "Contact Position"
    },
    {
        fieldType: "input",
        name: "contact_email",
        type: "text",
        label: "Contact Email"
    },
    {
        fieldType: "input",
        name: "contact_phone",
        type: "text",
        label: "Contact Phone"
    },
    {
        fieldType: "switch",
        name: "enabled",
        label: "Enabled"
    },
    {
        fieldType: "checkbox",
        name: "beacon",
        label: "Allow Access for Beacon"
    },
    {
        fieldType: "checkbox",
        name: "drco",
        label: "Allow Access for DRCO"
    }
]);

export const getSchoolDetailsConfig = (school: ISchool) => ({
    title: school.name,
    leftColumn: [
        {
            label: "School ID",
            value: school.id
        },
        {
            label: "Street Address",
            value: school.street_address
        },
        {
            label: "Apt/Suite",
            value: school.apt_suite
        },
        {
            label: "City, State",
            value: school.city + ", " + school.state
        },
        {
            label: "Postal Code",
            value: school.postal_code
        },
        {
            label: "SIS ID",
            value: school.sis_id
        }
    ],
    rightColumn: [
        {
            label: "District",
            value: get(school, ['district', 'name'])
        },
        {
            label: "Contact Name",
            value: school.contact_name
        },
        {
            label: "Contact Position",
            value: school.contact_position
        },
        {
            label: "Contact Email",
            value: school.contact_email
        },
        {
            label: "Contact Phone",
            value: school.contact_phone
        },
        {
            label: 'Access Allowed for Beacon',
            value: school.app_access.beacon.toString()
        },
        {
            label: 'Access Allowed for DRCO',
            value: school.app_access.drco.toString()
        },
    ]
});

export const getStudentsListColumns = (deleteStudent: (id: number) => void, viewStudent: (id: number) => void, editStudent: (id: number) => void) => ([
    {
        title: "Id",
        key: "id",
        getItemValue: (item: IStudent) => ({
            text: item.id
        })
    },
    {
        title: "Last name",
        key: "last_name",
        getItemValue: (item: IStudent) => ({
            text: item.last_name
        })
    },
    {
        title: "First name",
        key: "first_name",
        getItemValue: (item: IStudent) => ({
            text: item.first_name
        })
    },
    {
        title: "Grade",
        key: "grade",
        getItemValue: (item: IStudent) => ({
            text: item.grade
        })
    },
    {
        title: "Cohort",
        key: "cohort",
        getItemValue: (item: IStudent) => ({
            text: get(item, ['cohorts', '0', 'name'])
        })
    },
    {
        title: "School",
        key: "school",
        getItemValue: (item: IStudent) => ({
            text: item.school_name
        })
    },
    {
        title: "District",
        key: "district",
        getItemValue: (item: IStudent) => ({
            text: item.district_name
        })
    },
    {
        title: <img src={settings} alt="options"/>,
        style: "text-center",
        render: (item: IStudent) =>
            <TableOptionDropdown
                onDelete={()=> deleteStudent(item.id)}
                onEdit={()=>editStudent(item.id)}
                onView={()=>viewStudent(item.id)}
                confirmText="Delete Student"
                modalBodyText="Are you sure you would like to delete this student?"
            />
    }
]);

export const getStudentsFilterConfig = (cohorts: ICohort[], schools: ISchool[], districts: IDistrict[], grades: {id: string, name: string | number}[], archived: {id: number, name: string}[], userRole: string) => ([
    {
        fieldType: "input",
        name: "id",
        type: "number",
        placeholder: "ID",
        size: "small"
    },
    {
        fieldType: "input",
        name: "first_name",
        type: "text",
        placeholder: "First Name",
        size: "medium"
    },
    {
        fieldType: "input",
        name: "last_name",
        type: "text",
        placeholder: "Last Name",
        size: "medium"
    },
    {
        fieldType: "select",
        name: "grade",
        placeholder: "Grade",
        size: "medium",
        options: grades
    },
    {
        fieldType: "select",
        name: "is_archived",
        placeholder: "Show Archived",
        size: "medium",
        options: archived
    },
    isAdmin(userRole) &&
    {
        fieldType: "select",
        name: "cohort",
        placeholder: "Cohort",
        size: "large",
        options: cohorts
    },
    isDistrictAdminOrHigher(userRole) &&
    {
        fieldType: "select",
        name: "school",
        placeholder: "School",
        size: "large",
        options: schools
    },
    isAdmin(userRole) &&
    {
        fieldType: "select",
        name: "district",
        placeholder: "District",
        size: "large",
        options: districts
    }
]);

export const getStudentsEditConfig = (schools: ISchool[], cohorts: ICohort[], grades: {id: string, name: string | number}[], userRole: string) => ([
    {
        fieldType: "avatar",
        name: "avatar",
    },
    {
        fieldType: "input",
        name: "first_name",
        type: "text",
        label: "First name",
        readOnly: true
    },
    {
        fieldType: "input",
        name: "last_name",
        type: "text",
        label: "Last name",
        readOnly: true
    },
    {
        fieldType: "input",
        name: "sis_id",
        type: "text",
        label: "SIS ID"
    },
    {
        fieldType: "select",
        name: "grade",
        label: "Grade",
        options: grades
    },
    isDistrictAdminOrHigher(userRole) &&
    {
        fieldType: "typeahead_select",
        name: "school",
        label: "School",
        options: schools
    },
    isAdmin(userRole) &&
    {
        fieldType: "typeahead_select",
        name: "cohort",
        label: "Cohort",
        options: cohorts
    }
]);

export const getStudentDetailsConfig = (student: IStudent) => ({
    title: student.first_name + " " + student.last_name,
    leftColumn: [
        {
            label: "Student ID",
            value: student.id
        },
        {
            label: "Grade",
            value: student.grade
        },
        {
            label: "SIS ID",
            value: student.sis_id
        }
    ],
    rightColumn: [
        {
            label: "School",
            value: student.school_name
        },
        {
            label: "District",
            value: student.district_name
        }
    ]
});

export const getStudentUsersConfig = (removeUser: (id: number) => void, viewUser: (id: number) => void, editUser: (id: number) => void) => ([
    {
        title: "Id",
        key: "id",
        getItemValue: (item: IUser) => ({
            text: item.id
        })
    },
    {
        title: "Last Name",
        key: "last_name",
        getItemValue: (item: IUser) => ({
            text: item.last_name
        })
    },
    {
        title: "First Name",
        key: "first_name",
        getItemValue: (item: IUser) => ({
            text: item.first_name
        })
    },
    {
        title: "Email",
        key: "email",
        getItemValue: (item: IUser) => ({
            text: item.email
        })
    },
    {
        title: "Role",
        key: "role",
        getItemValue: (item: IUser) => ({
            text: get(item, ['role', 'display_name'])
        })
    },
    {
        title: "School",
        key: "school",
        getItemValue: (item: IUser) => ({
            text: get(item, ['school', '0', 'name'])
        })
    },
    {
        title: "District",
        key: "district",
        getItemValue: (item: IUser) => ({
            text: get(item, ['district', '0', 'name'])
        })
    },
    {
        title: <img src={settings} alt="options"/>,
        style: "text-center",
        render: (item: IStudent) =>
            <TableOptionDropdown
                onDelete={()=> removeUser(item.id)}
                onView={()=> viewUser(item.id)}
                onEdit={()=> editUser(item.id)}
                confirmText="Remove Access"
                modalBodyText="Are you sure you would like to remove access to this user?"
            />
    },
]);

export const getCohortsListColumns = (deleteCohort: (id: number) => void, viewCohort: (id: number) => void, editCohort: (id: number) => void) => ([
    {
        title: "Id",
        key: "id",
        getItemValue: (item: ICohort) => ({
            text: item.id
        })
    },
    {
        title: "Name",
        key: "name",
        getItemValue: (item: ICohort) => ({
            text: item.name
        })
    },
    {
        title: "Description",
        key: "description",
        getItemValue: (item: ICohort) => ({
            text: item.description
        })
    },
    {
        title: <img src={settings} alt="options"/>,
        style: "text-center",
        render: (item: IUser) =>
            <TableOptionDropdown
                onDelete={()=> deleteCohort(item.id)}
                onEdit={()=>editCohort(item.id)}
                onView={()=>viewCohort(item.id)}
                confirmText="Delete Cohort"
                modalBodyText="Are you sure you would like to delete this cohort?"
            />
    }
]);

export const getCohortsFilterConfig = () => ([
    {
        fieldType: "input",
        name: "id",
        type: "number",
        placeholder: "ID",
        size: "small"
    },
    {
        fieldType: "input",
        name: "name",
        type: "text",
        placeholder: "Name",
        size: "large"
    }
]);

export const getCohortsEditConfig = () => ([
    {
        fieldType: "input",
        name: "name",
        type: "text",
        label: "Name"
    },
    {
        fieldType: "textarea",
        name: "description",
        label: "Description"
    },
]);

export const getCohortDetailsConfig = (cohort: ICohort) => ({
    title: cohort.name,
    leftColumn: [
        {
            label: "Cohort ID",
            value: cohort.id
        },
        {
            label: "Name",
            value: cohort.name
        }
    ],
    rightColumn: [
        {
            label: "Description",
            value: cohort.description
        }
    ]
});

export const getConnectedAppsListColumns = (deleteApp: (id: number) => void, viewApp: (id: number) => void, editApp: (id: number) => void) => ([
    {
        title: "Id",
        key: "id",
        getItemValue: (item: IApp) => ({
            text: item.id
        })
    },
    {
        title: "Name",
        key: "name",
        getItemValue: (item: IApp) => ({
            text: item.name
        })
    },
    {
        title: <img src={settings} alt="options"/>,
        style: "text-center",
        render: (item: IUser) =>
            <TableOptionDropdown
                onDelete={()=> deleteApp(item.id)}
                onEdit={()=>editApp(item.id)}
                onView={()=>viewApp(item.id)}
                confirmText="Delete Connected App"
                modalBodyText="Are you sure you would like to delete this app?"
            />
    }
]);

export const getConnectedAppsFilterConfig = () => ([
    {
        fieldType: "input",
        name: "id",
        type: "number",
        placeholder: "ID",
        size: "small"
    },
    {
        fieldType: "input",
        name: "name",
        type: "text",
        placeholder: "Name",
        size: "large"
    }
]);

export const getConnectedAppEditConfig = () => ([
    {
        fieldType: "input",
        name: "name",
        type: "text",
        label: "Name"
    }
]);

export const getConnectedAppDetailsConfig = (app: IApp) => ({
    title: app.name,
    leftColumn: [
        {
            label: "App ID",
            value: app.id
        }
    ],
    rightColumn: [
        {
            label: "Name",
            value: app.name
        }
    ]
});

export const getSchoolStudentsFilterConfig = (cohorts: ICohort[], grades: {id: string, name: string | number}[], archived: {id: number, name: string}[], userRole: string) => ([
    {
        fieldType: "input",
        name: "id",
        type: "number",
        placeholder: "ID",
        size: "small"
    },
    {
        fieldType: "input",
        name: "first_name",
        type: "text",
        placeholder: "First Name",
        size: "medium"
    },
    {
        fieldType: "input",
        name: "last_name",
        type: "text",
        placeholder: "Last Name",
        size: "medium"
    },
    {
        fieldType: "select",
        name: "grade",
        placeholder: "Grade",
        size: "medium",
        options: grades
    },
    {
        fieldType: "select",
        name: "is_archived",
        placeholder: "Show Archived",
        size: "medium",
        options: archived
    },
    isAdmin(userRole) &&
    {
        fieldType: "select",
        name: "cohort",
        placeholder: "Cohort",
        size: "large",
        options: cohorts
    }
]);

export const getSchoolUsersFilterConfig = (roles: IRole[], cohorts: ICohort[], userRole: string) => ([
    {
        fieldType: "input",
        name: "id",
        type: "number",
        placeholder: "ID",
        size: "small"
    },
    {
        fieldType: "input",
        name: "email",
        type: "text",
        placeholder: "Email",
        size: "large"
    },
    {
        fieldType: "input",
        name: "first_name",
        type: "text",
        placeholder: "First Name",
        size: "medium"
    },
    {
        fieldType: "input",
        name: "last_name",
        type: "text",
        placeholder: "Last Name",
        size: "medium"
    },
    {
        fieldType: "select",
        name: "role",
        placeholder: "User Role",
        size: "medium",
        displayKey: "display_name",
        options: roles
    },
    isAdmin(userRole) &&
    {
        fieldType: "select",
        name: "cohort",
        placeholder: "Cohort",
        size: "large",
        options: cohorts
    }
]);
